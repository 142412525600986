<template>
  <div id="novosti">
    
    <div id=image-holder2>
      <h1>Novosti</h1>
    </div>
    <div id=novosti-sadrzaj>
      <!-- <section class="clanak">
        <div class="clanak-slika" style="background-image: url('https://bah.edu.rs/wp-content/uploads/2017/12/ulje-na-platnu-slika-van-gog.jpg')">
        </div>
        <div class="clanak-tekst">
          <h1>Naslov</h1>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam et nulla et lorem malesuada placerat vel non leo. Fusce rutrum sollicitudin tempus. Nullam magna magna, aliquet tristique neque ut, placerat dignissim turpis. Aliquam enim felis, facilisis vitae erat sed, aliquam sollicitudin risus. Vivamus euismod ante ac eros consequat dictum. Nullam neque eros, sagittis non odio sit amet, rhoncus feugiat dui. Nam auctor ex vitae est pretium, at interdum erat tempus. Etiam nec nunc consectetur, sollicitudin velit ut, egestas lorem. Vestibulum dictum urna vel ipsum porta, a faucibus lacus porta. Aenean tempus condimentum finibus. Mauris porttitor scelerisque urna, vitae mollis purus sagittis ut. Integer pretium turpis elementum lacus viverra ullamcorper.
          </p>
        </div>
      </section>
      <section class="clanak">
        <div class="clanak-slika" style="background-image: url('https://bah.edu.rs/wp-content/uploads/2017/12/ulje-na-platnu-slika-van-gog.jpg')">
        </div>
        <div class="clanak-tekst">
          <h1>Naslov</h1>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam et nulla et lorem malesuada placerat vel non leo. Fusce rutrum sollicitudin tempus. Nullam magna magna, aliquet tristique neque ut, placerat dignissim turpis. Aliquam enim felis, facilisis vitae erat sed, aliquam sollicitudin risus. Vivamus euismod ante ac eros consequat dictum. Nullam neque eros, sagittis non odio sit amet, rhoncus feugiat dui. Nam auctor ex vitae est pretium, at interdum erat tempus. Etiam nec nunc consectetur, sollicitudin velit ut, egestas lorem. Vestibulum dictum urna vel ipsum porta, a faucibus lacus porta. Aenean tempus condimentum finibus. Mauris porttitor scelerisque urna, vitae mollis purus sagittis ut. Integer pretium turpis elementum lacus viverra ullamcorper.
          </p>
        </div>
      </section> -->
      <section class="clanak" v-for="clanak in novosti" :key="clanak.id" @click="otvoriClanak(clanak.id)">
        <div class="clanak-slika" :style="'background-image: url(\''+clanak.slika+'\''+')'" >
        </div>
        <div class="clanak-tekst">
          <h1>{{clanak.naslov}}</h1>
          <p style="margin-bottom:5px">{{new Date(clanak.datum).toLocaleDateString("sr-Latn-RS")}}</p>
          <p class="opis">{{clanak.opis.substring(0,230)+"..."}}</p>
        </div>
      </section>
    </div>

  </div>
</template>

<script>
import axios from '@/axios';

export default {
  name:'Novosti',
  data: function(){
    return(
      {
        novosti:[]
      }
    );
  },
  methods:{  
    getData(){
      let self=this;
      axios.get('novosti.php').then(function (response) {
        console.log("OK! ", response, response.data);
        self.novosti=response.data;
        self.obradiClanke();
      })
      .catch(function (error) {
        console.log("ERROR",error.response);
      });
    },
    obradiClanke(){
      for(var clanak of this.novosti)
      {
        // clanak.tekst="<h1>"+clanak.naslov+"</h1>\n"+clanak.tekst;
        if(clanak.slika=='')
          clanak.slika=require("@/assets/novosti.jpg");
      }
    },
    otvoriClanak(id)
    {
      console.log("OTVORI CLANAK")
      this.$router.push('/clanak'+id);
    }
  },
  mounted(){
    this.getData();
  }
}
</script>

<style scoped>

#image-holder2{
  /* opacity:0.7; */
  background-image: url("../assets/novosti.jpg");
  background-position: center;
  background-size:cover;
  -webkit-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.75);
  box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.75);
}

#novosti-sadrzaj{
  min-height: 50vh;
  background-color: #dcdcdc;
  padding:70px 5% 100px 5%;
  display:flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.clanak{
  
  position: relative;
  display: flex;
  justify-content: flex-start;
  background-color: white;
  margin:20px;
  padding: 30px;
  /* border-color: #f5871eff; */
  border-width: 0.5px;
  border-radius: 0px;
  -webkit-box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.75);
  height:300px;
  width:45%;
  /* max-width:1000px; */

  animation-name: zoomout;
  animation-duration: 0.5s;
}

.clanak:hover{
  cursor: pointer;
  /* transform: scale(1.1); */
  
  animation-name: zoom;
  animation-duration: 0.5s;
  transform: scale(1.01);
}


@keyframes zoom {
   0% {transform: scale(1.0);}
   100% {transform: scale(1.01);}
}
@keyframes zoomout {
   0% {transform: scale(1.01);}
   100% {transform: scale(1.00);}
}



@media screen and (max-width: 1200px) {
  .clanak{
    width:100%;
  }
}

@media screen and (max-width: 600px) {
  .clanak{
    width:100%;
    height:80vh;
    flex-direction: column;
  }
}

.clanak-slika{
  height:100%;
  width:45%;
  max-width: 400px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.clanak-tekst{
  display:flex;
  flex-direction: column;
  flex-wrap: nowrap;
  position: relative;
  margin-left:5%;
  height: 100%;
  width:50%;
  /* overflow: hidden; */
  text-align:left;
  
}

.clanak-tekst h1{
  font-size:23px;
  font-weight: 600;
  text-align:left;
}

.clanak-tekst div{
  width:100%;
  max-height: 195px;
  text-align:left;
  overflow: hidden;
}

.opis{
  overflow: hidden;
  margin:0;
  text-overflow: ellipsis;
}

@media screen and (max-width: 600px) {
  .clanak-slika{
    order:1;
    width: 100%;
    height: 40vh;
  }
  .clanak-tekst{
    width:100%;
    margin:0;
    margin-bottom: 15px;
    height:40vh;
  }
}


</style>